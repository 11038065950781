<!-- Finder Pólizas de Asegurados -->

<template>
  <div class="polizas_F" v-if="schema">    
    <v-sheet :elevation="4">
      
      <!-- Cabecera -->
      <div class="cabecera" v-if="Entorno.header.header">       
        <base_Header 
          :Entorno="Entorno.header"
          @onEvent="event_Header">          
        </base_Header>
      </div>

      <!-- Contenido -->
      <div class="contenedor"> 

        <!-- Filtro de Busqueda -->
        <!-- <div @focusin="focusinFiltro()" :style="Entorno.filtro.style" :class="Entorno.filtro.class"> -->
        <div :style="Entorno.filtro.style" :class="Entorno.filtro.class">
          <div style="display:flex; align-items:center">
            <div class="columna" style="width:27rem;padding-right:5px">   
              <div style="display:flex">           
                <ctrl_multiFilter
                  style="width:100%"
                  :items="items_multifilter"
                  :schema="schema">
                </ctrl_multiFilter>

                <!-- Botón de busqueda -->
                <v-btn
                  style="margin-left:2px"
                  v-bind="$cfg.btn.busca"
                  @click="event_search('buscarAsegurado')">                
                    <v-icon dark>{{ $cfg.btn.busca.icono }}</v-icon>
                </v-btn>          
              </div>    
            </div>
          </div>
        </div>

        <!-- Grid -->
        <base_Fgrid :padre="stName" :Entorno="Entorno" @onEvent="event_Grid">
          <!-- Slot botonera Top -->
          <template v-slot:top_btns="{ Sitem }">         
            <div style="padding:10px 0 0 10px;">
              <btracombi
                :btra_permisos="schema.btra"
                :btra_particular="Entorno.btra"
                btra_modulo="top"
                @onEvent="event_btra_Top({ accion:$event.accion, row:Sitem })">
              </btracombi>
            </div>
          </template>

          <!-- Slot campos grid menos el de acciones -->
          <!-- <template v-slot:fields_grid="{ Sitem, Sheader }">          
            {{ get_fieldGrid(Sitem, Sheader) }}
          </template> -->

          <!-- Slot campo acciones del Grid -->
          <template v-slot:acciones="{ Sitem }">
            <btracombi
              :btra_permisos="schema.btra"
              :btra_particular="Entorno.btra"
              btra_modulo="mrow"
              @onEvent="event_btra_Row({ accion:$event.accion, row:Sitem })">
            </btracombi>
          </template>
        </base_Fgrid>
      </div>
    </v-sheet>
  </div>
</template>



<script>

  import { mixinFinder } from "@/mixins/mixinFinder.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base"); 
  const base_Fgrid = () => plugs.groute("base_Fgrid_maub.vue", "base");
  const btracombi = () => plugs.groute("btracombi.vue", "comp");
  const ctrl_multiFilter = () => plugs.groute("ctrl_multiFilter.vue", "comp");
    
  export default {
    mixins: [mixinFinder],
    components: { base_Header, base_Fgrid, btracombi, ctrl_multiFilter },
    props: {
      padre: { type:String, default: ''},
      componenteTipo: { type:String, default:'FM' },
      Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.F)); }}
    },
  

    data() {
      return {  
        schema:null,
        api:'polizas_F',
        stName:'stFpolizas',
        component_M:null,
        modal:false,
        accion:'',
        accionRow: {},        
        disparo:false,
     
        headers: {},
        ST_zonas: [],
        items_multifilter: [],
           
      };
    },
    
    
    methods: {
      ini_data() {
        console.log("DEV " + this.api + " ********************** (ini_data)");
        
        // guardo Schema del store en variable local
        this.schema = this.$store.state[this.stName].schema;

        // configuro Entorno 
        this.Entorno.header.titulo = "Busqueda de Asegurados";  

        this.headers = {
          header: [
            { text: "Acciones", value: "acciones", sortable: false, width: "auto", slot:true },
            { text: "Nombre", value: "name", witdh: "10rem" },
            { text: "Cia", value: "cia_name", witdh: "10rem" }, 
            { text: "Póliza", value: "poliza", witdh: "10rem" },            
            { text: "Dni", value: "dni", witdh: "10rem" },
            { text: "S", value: "sex", witdh: "10rem" },            
            { text: "Cobertura", value: "cober", witdh: "10rem" },
            { text: "F.Efecto", value: "fh", witdh: "10rem", filtro:'fecha' },
            { text: "F.Nacim", value: "fhnac", witdh: "10rem", filtro:'fecha' },
            { text: "Edad", value: "edad", witdh: "10rem" },
            { text: "Teléfono", value: "tfn", witdh: "10rem" },
            { text: "Población", value: "pob", witdh: "10rem" },
            //{ text: "Provincia", value: "pro", witdh: "10rem" },
            { text: "PU", value: "puni", witdh: "10rem" },
            { text: "??", value: "aviso", witdh: "10rem" }
          ],
        };

        this.Entorno.grid.headers = this.set_headers();

        // guardo nombre componente de Mto
        if (this.componenteTipo.indexOf('M')>= 0) this.component_M= 'funes_M';

        // obtengo items zonas 
        this.ST_zonas = this.$store.state.datos_iniciales.zonas;
        this.ST_zonas.unshift({ id: "0", zona: "Todas" });

        // controles del multiFilter
        this.items_multifilter = [
          { ctrl: "name", label: "Nombre", buscar: "buscarAsegurado" },
          { ctrl: "dni", label: "DNI", buscar: "buscarAsegurado" },
          { ctrl: "poliza", label: "Póliza", buscar: "buscarAsegurado" }         
        ];  
      },   


      //
      get_fieldGrid(item, header) {

        // prima única
        if (header.value== 'puni') {
          return item[header.value]==1? 'X' : '';
        }

        // edad
        /* if (header.value== 'edad') {              
          return this.calc_edad({ format:'fh'}, item['fhnac']);
        } */

        return item[header.value];
      },


      // Recoge eventos de busqueda
      event_search(nameBtn = "", header) {
        var ctrls= this.schema.ctrls;
        
        if (ctrls.name.value.length< 3 &&  ctrls.dni.value.length< 3 && ctrls.poliza.value.length< 3) {
          this.$root.$alert.open('Debe introducir más de 2 caracteres para la búsqueda', 'info');
          return;
        }

        this.buscarExec(nameBtn, header);
      },

      
    }
  }
</script>


